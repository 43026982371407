import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
// import * as dayjs from 'dayjs';
import { getCityList, getOtgList, getStreetList } from '../Axios/searchoff';
import Table from 'react-bootstrap/Table';
import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaginationLoe from './PaginationLoe';
import { defaultOptions, selectOptions } from '../utils/search';
import { formatUTC } from '../utils/utils';
import dayjs from 'dayjs';


const SearchGrafikOff = ({getPodiys, dno, type}) => {
  
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [selectedOtg, setSelectedOtg] = useState(null);
  const [selectedFrom, setSelectedFrom] = useState('');
  const [selectedTo, setSelectedTo] = useState('');

  const [cityOptions, setCityOptions] = useState([]);
  const [otgOptions, setOtgOptions] = useState([]);
  const [streetOptions, setStreetOptions] = useState([]);

  const [podiysList, setPodiysList] = useState([]);
  const [border, setBorder] = useState({otg: '#C4CBD2', city: '#C4CBD2', dateTo: "#C4CBD2", dateFrom: "#C4CBD2"});
  const [color, setColor] = useState({otg:'hsl(0, 0%, 50%)', city: 'hsl(0, 0%, 50%)'});

  const formRef = useRef(null);
  const citySelect = useRef(null);
  const streetSelect = useRef(null);
  const otgSelect = useRef(null);

  const tableOff = useRef(null);

  const [page, setPage] = useState(1);
  const [totalItems, setTotal] = useState(1);
  
  const [noOff, setNoOff] = useState('');

  const formatFrom = dayjs(selectedFrom).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
  const formatTo = dayjs(selectedTo).format('YYYY-MM-DDTHH:mm:ssZ[Z]');

  useEffect(()=>{
    // при першому завантаженні сторінки
    selectOptions(getCityList, setCityOptions); // витягуємо перелік всіх міст
    selectOptions(getOtgList, setOtgOptions); // витягуємо перелік всіх отг

  },[]);


  useEffect(() => {
    // встановелння періода для пошуку за замовчуванням поточний день + 5 днів (тільки для планових type - 0)
    if (type === 0) {
      const currentDate = dayjs(new Date()).format('YYYY-MM-DD');
      const toSearchDate = dayjs(currentDate).add(5, 'day').format('YYYY-MM-DD');
      setSelectedFrom(currentDate);
      setSelectedTo(toSearchDate)
    }
    // встановелння періода для пошуку за замовчуванням поточний день - 5 днів (тільки для аварійних type - 1)
    if (type === 1) {
      const currentDate = dayjs(new Date()).format('YYYY-MM-DD');
      const fromSearchDate = dayjs(currentDate).subtract(5, 'day').format('YYYY-MM-DD');
      setSelectedFrom(fromSearchDate);
      setSelectedTo(currentDate)
    }
  }, [type])

  useEffect(()=>{
    if(selectedOtg !== null){
      selectOptions(getCityList, setCityOptions, selectedOtg.value);
    }
    if(selectedCity !== null){
      selectOptions(getStreetList, setStreetOptions, selectedCity.value);
    }
  },[selectedOtg, selectedCity])
  
  const handleChangePage = (currentPage)=> {
    formRef.current.scrollIntoView({behavior: 'smooth'})
    setPage(currentPage);
    getPodiys(formatFrom, formatTo, selectedOtg?.value || '', selectedCity?.value || '', selectedStreet?.value || '', currentPage).then(response=>{
      const data = response['hydra:member'];
      setTotal(response['hydra:totalItems'])
      setPodiysList(data);
    })
  }

  function handleSubmit(e) {
    e.preventDefault();
    setPage(1);

    // відправка запиту в разі якщо дані заповнено місто + період або отг + період
    if ((selectedOtg !== null && selectedFrom !== '' && selectedTo !== '') || (selectedCity !== null && selectedFrom !== '' && selectedTo !== '')) {
      getPodiys(formatFrom, formatTo, selectedOtg?.value || '', selectedCity?.value || '', selectedStreet?.value || '', 1).then(response=>{
        const data = response['hydra:member'];
        setTotal(response['hydra:totalItems']);
        setPodiysList(data);
        if(data.length === 0) {
          toast.error('Вимкнень не знайдено 💡', {
            position: toast.POSITION.BOTTOM_LEFT
         });
         setNoOff('За обраним Вами періодом та населеним пунктом/вулицею/ОТГ відключень не зафіксовано.');
         clearData();
        }
      }).catch((err)=> {
        toast.error(`Помилка обробки даних ${err.message}`, {
            position: toast.POSITION.BOTTOM_LEFT
        });
        clearData();
      })
    }

    // відстеження незаповнених даних -- всі поля пусті

    if (selectedOtg === null && selectedCity === null && selectedFrom === '' && selectedTo === '') {
      setBorder({otg:'#e74c3c', city: '#e74c3c', dateTo: "#e74c3c", dateFrom: "#e74c3c"});
      setColor({otg:'#e74c3c', city:'#e74c3c'});
      setTimeout(() => {
        setBorder({otg:'#C4CBD2', city:'#C4CBD2', dateTo: "#C4CBD2", dateFrom: "#C4CBD2"});
        setColor({otg:'hsl(0, 0%, 50%)', city:'hsl(0, 0%, 50%)'});
      }, 4000);
      setNoOff('');
      setPodiysList([]);
    }

    // відстеження незаповнених даних -- незаповнений період
    if ((selectedOtg !== null || selectedCity !== null) && (selectedFrom === '' || selectedTo === '')) {
      setBorder({otg:'#C4CBD2', city: '#C4CBD2', dateTo: "#e74c3c", dateFrom: "#e74c3c"});
      setTimeout(() => {
        setBorder({otg:'#C4CBD2', city:'#C4CBD2', dateTo: "#C4CBD2", dateFrom: "#C4CBD2"});
      }, 4000);
      setNoOff('');
      setPodiysList([]);
    }

    // відстеження незаповнених даних -- незаповнений населений пункт/отг
    if ((selectedFrom !== '' || selectedTo !== '') && (selectedOtg === null && selectedCity === null)) {
      setBorder({otg:'#e74c3c', city: '#e74c3c', dateTo: "#C4CBD2", dateFrom: "#C4CBD2"});
      setColor({otg:'#e74c3c', city:'#e74c3c'});
      setTimeout(() => {
        setBorder({otg:'#C4CBD2', city:'#C4CBD2', dateTo: "#C4CBD2", dateFrom: "#C4CBD2"});
        setColor({otg:'hsl(0, 0%, 50%)', city:'hsl(0, 0%, 50%)'});
      }, 4000);
      setNoOff('');
      setPodiysList([]);
    }
  }
  
  function clearData(){
    otgSelect.current.clearValue();
    citySelect.current.clearValue(); 
    streetSelect.current.clearValue();

    if(selectedCity !== null){
      selectOptions(getCityList, setCityOptions);
    }
  }

  const dateOptions = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  
  const timeOptions = {
    timeZone: 'UTC',
    hour: 'numeric', 
    minute: 'numeric',
  };

  return (
      <div className="search-off">
        <form action="submit" onSubmit={handleSubmit} ref={formRef} data-aos="fade-up">
          <label htmlFor="otg">Об'єднана територіальна громада</label>
          <Select
            cacheOptions
            ref={otgSelect} 
            id={'otg'}
            placeholder={'Оберіть ОТГ'} 
            onChange={setSelectedOtg} 
            options={otgOptions}
            noOptionsMessage={() => 'Адреси не знайдено'} 
            loadingMessage={() => 'Завантаження...'}
            onSelectResetsInput = {false}
            onMenuOpen={clearData}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: `1px solid ${border.otg}`,
                width: '400px',
                display: 'flex',
                height: '58px',
                borderRadius: '4px',
                background: 'white',
                paddingLeft: '10px',
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                color: color.otg
              })
            }}
            onFocus={()=>{setBorder({...border, otg: '#C4CBD2'}); setColor({...color, otg: 'hsl(0, 0%, 50%)'})}}
            />
         <label htmlFor="city">Населений пункт</label>
          <Select
            cacheOptions
            ref={citySelect} 
            id={'city'}
            placeholder={'Оберіть ваш населений пункт'} 
            onChange={setSelectedCity} 
            defaultOptions={defaultOptions}
            options={cityOptions}
            noOptionsMessage={() => 'Адреси не знайдено'} 
            loadingMessage={() => 'Завантаження...'}
            onSelectResetsInput = {false}
            onMenuOpen={() => {
              citySelect.current.clearValue(); 
              streetSelect.current.clearValue();
            }}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                border: `1px solid ${border.city}`,
                width: '400px',
                display: 'flex',
                height: '58px',
                borderRadius: '4px',
                background: 'white',
                paddingLeft: '10px',
              }),
              placeholder: (baseStyles) => ({
                ...baseStyles,
                color: color.city
              })
            }}
            onFocus={()=>{setBorder({...border, city: '#C4CBD2'}); setColor({...color, city: 'hsl(0, 0%, 50%)'})}}
            />
         <label htmlFor="street">Вулиця</label>
         <Select 
            cacheOptions
            ref={streetSelect}
            id={'street'}
            placeholder={(selectedCity === null) ? 'Заповніть поле "Населений пункт"' : "Оберіть вашу вулицю"} 
            onChange={setSelectedStreet} 
            options={streetOptions}
            noOptionsMessage={() => 'Адреси не знайдено'} 
            loadingMessage={() => 'Завантаження...'}
            isDisabled={selectedCity === null}
            onMenuOpen={()=>streetSelect.current.clearValue()}
          />
          <div className="period-inner">
            <div className="period-inner__field">
             <label htmlFor="from">Період з</label>
             <input type="date" 
              className={selectedFrom !== '' ? 'date-input--has-value' : ''} 
              id='from' 
              value={selectedFrom} 
              onChange={(e)=> {setSelectedFrom(e.target.value)}}
              style={{borderColor: border.dateFrom}}  
            />
            </div>
            <div className="period-inner__field">
             <label htmlFor="to">Період по</label>
             <input type="date" 
              className={selectedTo !== '' ? 'date-input--has-value' : ''} 
              id='to' 
              value={selectedTo} 
              onChange={(e)=> {setSelectedTo(e.target.value)}}
              style={{borderColor: border.dateTo}} 
            />
            </div>
          </div>           
         <button className='power-btn'>Знайти</button>
         <ToastContainer/> 
       </form>
        {podiysList.length > 0 ?
        <>
          <div className="arrow-inner">	
            <div className="scroll-left" onClick={()=>{tableOff.current.scrollLeft -= 120;}}>&#8592;</div> 
            <div className="scroll-right" onClick={()=>{tableOff.current.scrollLeft += 120;}}>&#8594;</div>
          </div>
        <div className="search-off__table" ref={tableOff}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ОТГ</th>
              <th>Населений пункт</th>
              <th>Вулиця</th>
              <th>Будинок</th>
              <th>Тип відключення</th>
              <th>Час відключення</th>
              {type ? <th>Час включення</th> : <th>Очікуваний час включення</th> }
              {dno && <th>ТП</th>}
            </tr>
          </thead>
          <tbody>
            {podiysList.map(podiy=>{
              return <tr key={podiy['@id']}>
                        <td>{podiy.otg.name}</td>
                        <td style={{textAlign: 'center'}}>{podiy.city.name}</td>
                        <td>{podiy.street.name}</td>
                        <td className='buildings-td' style={{maxWidth: '350px', wordWrap: 'break-word'}}>{podiy.buildingNames}</td>
                        <td style={{textAlign: 'center'}}>
                          {/* type - передається з батьківського компоненту */}
                          {type === 0 && 'Планове відключення'}
                          {type === 1 && 'Аварійне відключення'}
                        </td>
                        <td style={{textAlign: 'center', minWidth: '150px'}}>{formatUTC(podiy.dateEvent, dateOptions, timeOptions)}</td>
                        <td style={{textAlign: 'center', minWidth: '150px'}}>{formatUTC(podiy.datePlanIn, dateOptions, timeOptions)}</td>
                        {dno && <td>{podiy.dno}</td>}
                    </tr>
            })}
          </tbody>
          </Table>
          </div>
               <PaginationLoe
                changePage={handleChangePage}
                currentPage={page}
                total={totalItems}
                limit={30}
           /> 
        </> 
        : <p className="off-msg"><strong>{noOff}</strong></p>
      }
      {(podiysList.length > 0 || noOff.length > 0) && 
          <div className="date-synk grafics">
            — Інформацію сформовано станом на {dayjs(new Date()).format('DD.MM.YYYY HH:mm')}
          </div>
      }
      </div>
  )
}

export default SearchGrafikOff